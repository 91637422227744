import AWS from "aws-sdk";

export const AwsDocSign = (docLink: String) => {
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: "ap-south-1",
  });
  let secureLink;

  const s3 = new AWS.S3();

  const params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
    Key: docLink?.split("/").splice(4, 4).join("/"),
    Expires: 600, // Expiration time in seconds
  };

  s3.getSignedUrl("getObject", params, (err: any, url: any) => {
    secureLink = url;
  });
  return secureLink;
};
