// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// locales
import ThemeLocalization from "./locales";
// components
import SnackbarProvider from "./components/snackbar";
import { ThemeSettings } from "./components/settings";
import { MotionLazyContainer } from "./components/animate";
import AutoLogout from "./components/customFunctions/AutoLogout";

//React query
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

// ----------------------------------------------------------------------

export default function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false, // Disable automatic refetching on error for all queries
      },
    },
  });
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <AutoLogout>
          <ThemeSettings>
            <ThemeLocalization>
              <SnackbarProvider>
                <QueryClientProvider client={queryClient}>
                  <Router />
                  {/* <ReactQueryDevtools
                    initialIsOpen={true}
                    position={"bottom-right"}
                  /> */}
                </QueryClientProvider>
              </SnackbarProvider>
            </ThemeLocalization>
          </ThemeSettings>
        </AutoLogout>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
