// ----------------------------------------------------------------------

import { report } from "process";
import QRCollection from "src/sections/Reports/QRCollection";

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = "/auth";
const ROOT_STEPS = "/new";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: "/login",
  register: "/register",
};

export const STEP_DASHBOARD = {
  steps: path(ROOT_STEPS, "/user/registrationsteps"),
  esignature: path(ROOT_STEPS, "/user/esignature"),
  verifyusernpin: path(ROOT_STEPS, "/user/verifyuserotp"),
  createnpin: path(ROOT_STEPS, "/user/createnpin"),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  mystats: path(ROOTS_DASHBOARD, "/mystats"),
  services: path(ROOTS_DASHBOARD, "/services"),
  network: path(ROOTS_DASHBOARD, "/network"),
  transaction: {
    root: path(ROOTS_DASHBOARD, "/transaction"),
    mytransaction: path(ROOTS_DASHBOARD, "/transaction/mytransaction"),
    fundflow: path(ROOTS_DASHBOARD, "/transaction/fundflow"),
    walletladger: path(ROOTS_DASHBOARD, "/transaction/walletladger"),
    report: path(ROOTS_DASHBOARD, "/transaction/report"),
    WalletToWallet: path(ROOTS_DASHBOARD, "/transaction/WalletToWallet"),
    QRCollection: path(ROOTS_DASHBOARD, "/transaction/QRCollection"),
  },
  scheme: {
    root: path(ROOTS_DASHBOARD, "/scheme"),
    allscheme: path(ROOTS_DASHBOARD, "/scheme/allscheme"),
    bbpsscheme: path(ROOTS_DASHBOARD, "/scheme/bbpsscheme"),
  },
  fundmanagement: {
    root: path(ROOTS_DASHBOARD, "/fundmanagement"),
    funddeposits: path(ROOTS_DASHBOARD, "/fundmanagement/funddeposits"),
    mybankaccount: path(ROOTS_DASHBOARD, "/fundmanagement/mybankaccount"),
    aepssettlement: path(ROOTS_DASHBOARD, "/fundmanagement/aepssettlement"),
    myfundrequest: path(ROOTS_DASHBOARD, "/fundmanagement/myfundrequest"),
    managefundflow: path(ROOTS_DASHBOARD, "/fundmanagement/managefundflow"),
    funddeposittable: path(ROOTS_DASHBOARD, "/fundmanagement/funddeposittable"),
    WalletToWalletTransfer: path(ROOTS_DASHBOARD, "/fundmanagement/WalletToWalletTransfer"),
  },
  setting: path(ROOTS_DASHBOARD, "/setting"),
  helpsupport: path(ROOTS_DASHBOARD, "/helpsupport"),
  userprofilepage: path(ROOTS_DASHBOARD, "/userprofilepage"),
};
