import { memo } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box, BoxProps } from "@mui/material";

// ----------------------------------------------------------------------

function DangerIcon({ ...other }: BoxProps) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const WARNING_LIGHT = theme.palette.warning.light;

  return (
    <Box {...other}>
      <svg width="34" height="36" viewBox="0 0 34 36" fill="none">
        <path
          d="M10.0835 13.4167H11.9168V15.25H10.0835V13.4167ZM10.0835 17.0833H11.9168V22.5833H10.0835V17.0833ZM11.0002 8.83333C5.94016 8.83333 1.8335 12.94 1.8335 18C1.8335 23.06 5.94016 27.1667 11.0002 27.1667C16.0602 27.1667 20.1668 23.06 20.1668 18C20.1668 12.94 16.0602 8.83333 11.0002 8.83333ZM11.0002 25.3333C6.95766 25.3333 3.66683 22.0425 3.66683 18C3.66683 13.9575 6.95766 10.6667 11.0002 10.6667C15.0427 10.6667 18.3335 13.9575 18.3335 18C18.3335 22.0425 15.0427 25.3333 11.0002 25.3333Z"
          fill="#2B3382"
        />
      </svg>
    </Box>
  );
}

export default DangerIcon;
