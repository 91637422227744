import { Box, Button, Container, Stack, Typography } from "@mui/material";
import Header from "./Header";
import Main from "./Main";
import { Outlet } from "react-router";
import { StyledContent, StyledRoot, StyledSection } from "./styles";
import Image from "src/components/image/Image";
import illustrate from "src/assets/icons/illustrate.png";
import { useAuthContext } from "src/auth/useAuthContext";

// ----------------------------------------------------------------------

export default function StepsLayout() {
  const { logout } = useAuthContext();
  return (
    <>
      <Main>
        {/* <Header /> */}{" "}
        <Button
          sx={{
            position: "absolute",
            top: 10,
            right: { xs: 10, md: 50 },
            zIndex: 99999,
          }}
          variant="contained"
          onClick={logout}
        >
          {" "}
          Logout
        </Button>
        <StyledRoot>
          {/* <Logo
        sx={{
          zIndex: 9,
          position: "absolute",
          mt: { xs: 1, md: 1 },
          ml: { xs: 1, md: 5 },
        }}
      /> */}

          <StyledSection>
            <Image src={illustrate} alt="Illustrate" />
          </StyledSection>

          <StyledContent>
            <Outlet />
          </StyledContent>
        </StyledRoot>
      </Main>
      <Box
        component="footer"
        sx={{
          position: "fixed",
          width: "100%",
          bottom: 0,
          backgroundColor: "#375168",
        }}
      >
        {/* <Divider /> */}
        <Container>
          <Stack
            flexDirection={{ xs: "column", sm: "row" }}
            sx={{ color: "white", pt: 1, pb: 1 }}
            justifyContent="space-between"
          >
            <Typography variant="subtitle2">
              Helpline Numbers +{process.env.REACT_APP_COMPANY_MOBILE} ,{" "}
              {process.env.REACT_APP_COMPANY_MOBILEOTHER}
            </Typography>
            <Typography variant="subtitle2">
              Timings : 08:00AM to 10:00 PM (Mon-Sun)
            </Typography>
            <Typography variant="subtitle2">
              Email : {process.env.REACT_APP_COMPANY_EMAIL}
            </Typography>
          </Stack>
        </Container>
      </Box>
    </>
  );
}
