// @mui
import {
  Alert,
  Tooltip,
  Stack,
  Typography,
  Link,
  Box,
  Button,
  Card,
} from "@mui/material";
// hooks
import { useAuthContext } from "../../auth/useAuthContext";
// layouts
import LoginLayout from "../../layouts/login";
//
import AuthLoginForm from "./AuthLoginForm";
import AuthWithSocial from "./AuthWithSocial";
import { useNavigate } from "react-router";
import { PATH_AUTH } from "src/routes/paths";
import DangerIcon from "src/assets/icons/dangerIcon";

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuthContext();
  const navigate = useNavigate();

  return (
    <LoginLayout>
      <Typography
        sx={{
          zIndex: 9,
          position: "absolute",
          top: "3.5%",
          right: "5%",
        }}
      >
        Don't have an account yet?{" "}
        <Link
          onClick={() => navigate(PATH_AUTH.register)}
          sx={{ cursor: "pointer", textDecoration: "underline" }}
        >
          Register
        </Link>
      </Typography>

      <Card
        sx={{
          p: 3,
          width: { xs: "95%", sm: 500 },
          margin: "auto",
          borderRadius: 1,
        }}
      >
        <Stack spacing={2} sx={{ mb: 5, position: "relative" }}>
          <Typography variant="h4">Login to Account</Typography>
          <Typography variant="caption">Enter your details below</Typography>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            sx={{ backgroundColor: "#E5F6FD", p: 0.5 }}
          >
            <DangerIcon />
            <Typography color="primary">
              {" "}
              Registered email id or Mobile Number{" "}
            </Typography>
          </Stack>

          <Tooltip title={method} placement="left">
            <Box
              component="img"
              alt={method}
              src={`/assets/icons/auth/ic_${method}.png`}
              sx={{ width: 32, height: 32, position: "absolute", right: 0 }}
            />
          </Tooltip>
        </Stack>

        <AuthLoginForm />
      </Card>
    </LoginLayout>
  );
}
