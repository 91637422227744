// @mui
import { Typography, Stack, Box, Container, useTheme } from "@mui/material";
// components
import Logo from "../../components/logo";
//
import {
  StyledRoot,
  StyledSectionBg,
  StyledSection,
  StyledContent,
} from "./styles";
import Marquee from "react-fast-marquee";
import SvgColor from "src/components/svg-color";
import DashboardIcon from "src/assets/icons/DashboardIcon";
import illustrate from "src/assets/icons/illustrate.png";
import Image from "src/components/image/Image";

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
};

export default function LoginLayout({ children, illustration, title }: Props) {
  const theme = useTheme();
  return (
    <StyledRoot>
      {/* <Logo
        sx={{
          zIndex: 9,
          position: "absolute",
          mt: { xs: 1, md: 1 },
          ml: { xs: 1, md: 5 },
        }}
      /> */}

      <StyledSection>
        {/* <Typography
          variant="h5"
          sx={{ mb: 5, maxWidth: 580, textAlign: "center" }}
        >
          Join Tramo to drive the Financial Inclusion in the country!
        </Typography> */}

        <Image src={illustrate} alt="Illustrate" />
        {/* 
        <Typography
          variant="subtitle1"
          sx={{ mt: 5, mb: 10, maxWidth: 580, textAlign: "center" }}
        >
          Be part of India’s largest retail digital solution providers, offer
          solutions available on your finger tips and earn commissions on every
          transactions.
        </Typography> */}

        {/* <StyledSectionBg /> */}
      </StyledSection>

      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
